/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onClientEntry = () => {
  // noop window.amplitude for local dev
  if (!window.amplitude) {
    window.amplitude = {
      getInstance: () => {
        return {
          logEvent: () => {},
        };
      },
    };
  }

  // noop sentry for local dev
  if (!window.Sentry) {
    window.Sentry = {
      configureScope: () => {
        return () => {};
      },
      captureException: () => {},
    };
  }
};
